<template>
  <div>
    <b-card no-body class="business-card">
      <b-card-header>
        <b-card-title>Task Lists</b-card-title>
        <feather-icon
          icon="PlusIcon"
          size="20"
          class="cursor-pointer"
          @click="getAddTaskModel"
          v-if="permission.create"
        />
      </b-card-header>

      <b-card-body>
        <vue-perfect-scrollbar :settings="60" style="height: 42vh">
          <div>
            <div class="all_border">
              <div
                class="task_style"
                v-for="(task, index) in tasks"
                v-if="tasks.length"
                :key="index"
              >
                <div class="row justify-content-between align-items-center">
                  <div class="col-lg-3">
                    <div class="font-weight-bolder">
                      {{ task.title }}
                    </div>
                    <div class="font-small-2 text-muted" v-if="task.main_task">
                      {{ task.main_task.title }}
                    </div>
                  </div>
                  <div class="col-lg-3 text-center">
                    <Progress
                      :percent="
                        task.done_percentage > 0 ? task.done_percentage : 0
                      "
                      :stroke-width="10"
                    />
                    <small class="text-muted">
                      {{ task.start_date | date_short }} -
                      <span class="text-danger">{{
                        task.end_date | date_time_format_short
                      }}</span>
                    </small>
                  </div>
                  <div class="col-lg-3">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <b-badge
                          style="margin: 5px"
                          :variant="
                            task.priority == 1
                              ? 'danger'
                              : task.priority == 2
                              ? 'primary'
                              : 'warning'
                          "
                        >
                          {{
                            task.priority == 1
                              ? "High"
                              : task.priority == 2
                              ? "Medium"
                              : "Low"
                          }}
                        </b-badge>
                        <b-badge
                          style="margin: 5px"
                          :variant="
                            task.is_progress == 1
                              ? 'warning'
                              : task.is_progress == 2
                              ? 'primary'
                              : task.is_progress == 3
                              ? 'success'
                              : 'danger'
                          "
                        >
                          {{
                            task.is_progress == 1
                              ? "Not Started"
                              : task.is_progress == 2
                              ? "On Going"
                              : task.is_progress == 3
                              ? "Finished"
                              : "Cancel"
                          }}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="d-flex justify-content-end align-items-center">
                      <b-badge
                        v-if="task.duration > 0"
                        pill
                        style="margin-right: 8px"
                        :variant="'light-primary'"
                      >
                        {{ task.duration }} Hour
                      </b-badge>
                      <b-badge
                        v-if="task.qty > 0"
                        pill
                        style="margin-right: 8px"
                        :variant="'light-success'"
                      >
                        {{ task.qty }} Qty.
                      </b-badge>
                      <div>
                        <b-avatar-group
                          size="32"
                          class="mr-1"
                          v-if="task.employees.length > 1"
                        >
                          <!-- :src="
                        require('@/assets/images/portrait/small/avatar-s-20.jpg')
                      " -->
                          <b-avatar
                            v-for="(employee, index) in task.employees"
                            :key="index"
                            variant="info"
                            v-b-tooltip.hover
                            class="pull-up"
                            :title="employee.name"
                          >
                            {{ getChar(employee.name) }}
                          </b-avatar>
                        </b-avatar-group>
                        <b-avatar
                          v-if="task.employees.length == 1"
                          v-for="(employee, index) in task.employees"
                          :key="index"
                          variant="info"
                          v-b-tooltip.hover
                          class="pull-up mr-1"
                          :title="employee.name"
                        >
                          {{ getChar(employee.name) }}
                        </b-avatar>
                      </div>
                      <button
                        class="btn btn-primary btn-sm"
                        :disabled="task.done_percentage >= 100"
                        @click="edit(task.id)"
                        v-if="permission.edit"
                      >
                        <feather-icon icon="EditIcon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vue-perfect-scrollbar>
      </b-card-body>
    </b-card>
    <Modal
      :title="(form_task.id == '' ? 'Create' : 'Update') + ' Task'"
      v-model="create_task_model"
      :mask-closable="false"
      :footer-hide="true"
      :closable="false"
      width="700"
    >
      <validation-observer ref="taskValidation">
        <b-form
          class="auth-login-form"
          @submit.prevent
          enctype="multipart/form-data"
        >
          <div class="row mb-2">
            <div class="col-md-6">
              <b-form-group label="Task Title" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="Task Title"
                  rules="required"
                >
                  <Input
                    name="title"
                    type="text"
                    v-model="form_task.title"
                    id="title"
                    placeholder="Enter Task Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Select Type" label-for="type">
                <validation-provider
                  #default="{ errors }"
                  name="Type"
                  rules="required"
                >
                  <Select
                    v-model="form_task.type"
                    filterable
                    name="type"
                    id="type"
                    placeholder="Select Type"
                  >
                    <Option :value="1">Check List</Option>
                    <Option :value="2">Time Tracker</Option>
                    <Option :value="3">Progressbar</Option>
                    <Option :value="4">Feedback</Option>
                    <Option :value="5">Hour</Option>
                    <Option :value="6">Quantity</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Task Quantity" label-for="qty">
                <validation-provider
                  #default="{ errors }"
                  name="Task Quantity"
                  :rules="form_task.type == 6 ? 'required' : ''"
                >
                  <InputNumber
                    name="qty"
                    v-model="form_task.qty"
                    id="qty"
                    placeholder="Enter Task Quantity"
                    class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Task Duration (Hour)" label-for="duration">
                <validation-provider
                  #default="{ errors }"
                  name="Task Duration"
                  :rules="form_task.type == 5 ? 'required' : ''"
                >
                  <InputNumber
                    name="duration"
                    v-model="form_task.duration"
                    id="duration"
                    placeholder="Enter Task Duration"
                    class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Start Date" label-for="start_date">
                <validation-provider
                  #default="{ errors }"
                  name="Start Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form_task.start_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select Start Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="End Date" label-for="end_date">
                <validation-provider
                  #default="{ errors }"
                  name="End Date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form_task.end_date"
                    class="form-control"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Select End Date"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Select Priority" label-for="priority">
                <validation-provider
                  #default="{ errors }"
                  name="Priority"
                  rules="required"
                >
                  <Select
                    v-model="form_task.priority"
                    filterable
                    name="priority"
                    id="priority"
                    placeholder="Select Priority"
                  >
                    <Option :value="1">High</Option>
                    <Option :value="2">Medium</Option>
                    <Option :value="3">Low</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Is Progress" label-for="is_progress">
                <validation-provider
                  #default="{ errors }"
                  name="Is Progress"
                  :rules="form_task.id == '' ? 'required' : ''"
                >
                  <Select
                    v-model="form_task.is_progress"
                    filterable
                    name="is_progress"
                    id="is_progress"
                    placeholder="Is Progress"
                  >
                    <Option :value="1">Not Started</Option>
                    <Option :value="2">On Going</Option>
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group label="Select Employee" label-for="employee_id">
                <validation-provider
                  #default="{ errors }"
                  name="Employee"
                  rules="required"
                >
                  <Select
                    multiple
                    v-model="form_task.employee_id"
                    filterable
                    name="employee_id"
                    id="employee_id"
                    placeholder="Select Employee"
                  >
                    <Option
                      :value="employee.id"
                      v-for="(employee, index) in employees"
                      :key="index"
                      v-if="employees.length"
                      >{{ employee.name }}</Option
                    >
                  </Select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-12">
              <b-form-group
                label="Description"
                label-for="description"
                rules="required"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <Input
                    v-model="form_task.description"
                    type="textarea"
                    :rows="2"
                    placeholder="Enter Your Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="text-right">
            <b-button
              type="submit"
              class="mx-1"
              variant="outline-primary"
              @click="CloseTask"
            >
              Close
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              @click="validationTaskForm"
            >
              {{ form_task.id == "" ? "Create" : "Update" }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </Modal>
  </div>
</template>

<script>
import {
  BCardBody,
  BCardHeader,
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
  BBadge,
  BAvatar,
  BDropdownDivider,
  VBTooltip,
  BAvatarGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import _ from "lodash";

export default {
  props: ["id", "employees"],
  components: {
    BCardHeader,
    BCardBody,
    VuePerfectScrollbar,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BAvatarGroup,
    flatPickr,
    BBadge,
    BAvatar,
    VBTooltip,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      create_task_model: false,
      form_task: {
        id: "",
        main_task_id: null,
        default_task_id: null,
        project_id: this.id,
        title: null,
        start_date: null,
        end_date: null,
        priority: null,
        is_progress: null,
        qty: null,
        duration: null,
        type: null,
        employee_id: [],
        description: null,
      },
    };
  },
  mounted() {
    this.$store.dispatch("getProjectTask", this.id);
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    getAddTaskModel() {
      this.create_task_model = true;
    },
    getPercentage(total, complete) {
      if (total > 0) {
        return Math.round((complete / total) * 100);
      } else {
        return 0;
      }
    },
    getChar(text) {
      return text
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase()
        .substring(0, 3);
    },
    addTask() {
      axios
        .post("/project/tasks/store", this.form_task)
        .then((res) => {
          this.s(res.data.message);
          this.$store.dispatch("getProjectTask", this.id);
          this.CloseTask();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.default_task_id)
              this.e(e.response.data.errors.default_task_id[0]);
            if (e.response.data.errors.main_task_id)
              this.e(e.response.data.errors.main_task_id[0]);
            if (e.response.data.errors.project_id)
              this.e(e.response.data.errors.project_id[0]);
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.start_date)
              this.e(e.response.data.errors.start_date[0]);
            if (e.response.data.errors.end_date)
              this.e(e.response.data.errors.end_date[0]);
            if (e.response.data.errors.priority)
              this.e(e.response.data.errors.priority[0]);
            if (e.response.data.errors.is_progress)
              this.e(e.response.data.errors.is_progress[0]);
            if (e.response.data.errors.qty)
              this.e(e.response.data.errors.qty[0]);
            if (e.response.data.errors.duration)
              this.e(e.response.data.errors.duration[0]);
            if (e.response.data.errors.type)
              this.e(e.response.data.errors.type[0]);
            if (e.response.data.errors.employee_id)
              this.e(e.response.data.errors.employee_id[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
          }
        });
    },

    async edit(id) {
      const task = await this.callApi("post", "/project/tasks/edit/" + id);
      if (task.status == 200) {
        this.form_task.id = task.data.task.id;
        this.form_task.default_task_id = task.data.task.default_task_id;
        this.form_task.title = task.data.task.title;
        this.form_task.start_date = task.data.task.start_date;
        this.form_task.end_date = task.data.task.end_date;
        this.form_task.priority = task.data.task.priority;
        this.form_task.is_progress = task.data.task.is_progress;
        this.form_task.qty = task.data.task.qty;
        this.form_task.duration = task.data.task.duration;
        this.form_task.type = task.data.task.type;
        this.form_task.description = task.data.task.description;
        this.form_task.employee_id = [];
        this.getAddTaskModel();
        task.data.task.employees.forEach((data) => {
          this.form_task.employee_id.push(data.employee_id);
        });

        // employee_id: [],
        // description: null,
      }
    },

    CloseTask() {
      this.form_task.id = "";
      this.form_task.main_task_id = null;
      this.form_task.title = null;
      this.form_task.start_date = null;
      this.form_task.end_date = null;
      this.form_task.priority = null;
      this.form_task.is_progress = null;
      this.form_task.employee_id = [];
      this.form_task.description = null;
      this.form_task.qty = null;
      this.form_task.duration = null;
      this.form_task.type = null;
      this.create_task_model = false;
    },

    validationTaskForm() {
      this.$refs.taskValidation.validate().then((success) => {
        if (success) {
          this.addTask();
        }
      });
    },
  },
  computed: {
    tasks() {
      return this.$store.getters.getProjectTask;
    },
    permission() {
      return this.$store.getters.getPermissions.task;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.task_style {
  border-bottom: 1px solid #d8d8d886;
  padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s, border-color 0s;
  overflow: hidden;
}
.all_border {
  border: 1px solid #d8d8d886;
  border-bottom: none;
  border-radius: 0.357rem;
}
.task_style:hover {
  transform: translateY(-4px);
  box-shadow: 0 3px 10px 0 #94949441;
}
</style>
