<template>
  <b-card no-body class="card-employee-task">
    <b-card-header>
      <b-card-title>Member Lists</b-card-title>
      <!-- <feather-icon icon="PlusIcon" size="20" class="cursor-pointer" /> -->
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <vue-perfect-scrollbar :settings="60" style="height: 42vh">
        <div
          class="employee-task d-flex justify-content-between align-items-center"
          v-for="(employee, index) in employees"
          :key="index"
          v-if="employees.length"
        >
          <b-media no-body>
            <b-media-aside class="mr-75">
              <b-avatar rounded size="42" />
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="mb-0">{{ employee.name }}</h6>
              <small>
                <span v-if="employee.designation">{{
                  employee.designation.name
                }}</span>
                <span v-if="employee.department"
                  >, {{ employee.department.name }}</span
                >
              </small>
            </b-media-body>
          </b-media>
          <div class="d-flex align-items-center">
            <small class="text-muted mr-75"
              >{{ employee.total_task }} Tasks</small
            >
            <!-- <span class="text-danger">
              <feather-icon
                icon="Trash2Icon"
                size="20"
                class="cursor-pointer"
              />
            </span> -->
          </div>
        </div>
      </vue-perfect-scrollbar>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BAvatar,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  props: ["employees"],
  data() {
    return {
    };
  },
  mounted() {},
  methods: {},
  components: {
    BCard,
    BImg,
    BAvatar,
    BCardText,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    VuePerfectScrollbar,
  },
};
</script>

<style></style>
